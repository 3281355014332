import tw from 'twin.macro';

export const Container = tw.section`py-10 px-6 max-w-2xl mx-auto bg-transparent`;

export const SubTitle = tw.h4`font-bold text-base font-display leading-normal mt-6`;

export const Section = tw.section`w-full mx-auto text-center py-24 px-8 md:px-16 bg-primaryLight`;

export const Title = tw.h2`font-display w-full my-2 text-2xl md:text-3xl lg:text-5xl font-bold leading-tight text-center`;

export const Text = tw.h3`font-display text-xl lg:text-3xl leading-tight`;

export const Descriptor = tw.div`font-display text-base tracking-wide`;
