import React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';

import { Hero } from '../components/Hero/';
import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import FAQAccordion from '../components/FAQAccordion/';
import { Badges } from '../components/Badges/';
import { Container, SubTitle, Section, Title, Text, Descriptor } from '../styles/droppoint-faq';

const DropPointFAQ = ({ data: { Postbote } }) => (
  <PageLayout className="bg-white">
    <SEO title="Häufig gestellte Fragen - DropPoints" />
    <Hero image={Postbote} title="Häufig gestellte Fragen" subTitle="zu DropPoints">
      <Descriptor>Jetzt die DropFriends-App downloaden.</Descriptor>
      <Badges />
    </Hero>
    <Container>
      <FAQAccordion question="Wie werde ich ein DropPoint?">
        <p className="mb-4">
          DropPoints sind vergütete Annahmestellen von Paketsendungen, die Deine Community aufgrund
          von Arbeitszeiten oder wichtigen Terminen nicht entgegennehmen kann. Klingelt also der
          Paketbote, dann ist die Zustellung der Sendungen beim Eigentümer nicht erfolgreich. Die
          Folge ist z.B. eine ungefragte Umleitung zu unbekannten und zeitlich eingeschränkten
          Nachbarn oder zu Filialen mit ungünstigen Öffnungszeiten bzw. weit entfernten Standorten.
        </p>

        <p className="mb-4">
          Somit müssen die Sendungseigentümer ihre Tagesplanungen nach den Vorgaben der Filialen
          richten oder darauf hoffen, dass Nachbarn rechtzeitig die Tür öffnen.
        </p>

        <p className="mb-4">
          Mit Hilfe von DropPopints sind Abholzeiten bereits vor der Bestellung von Online-Einkäufen
          geregelt. So können Sendungseigentümer ihren Wunsch-DropPoint ganz nach ihren Bedürfnissen
          und Alltagsplänen auswählen. Das spart Zeit, ist umweltfreundlich und sichert die
          Erstzustellung von Sendungen.
        </p>

        <p className="mb-4">
          Somit entscheidet nicht mehr der Paketbote was mit dem Eigentum Anderer geschieht. Das ist
          nur fair.
        </p>

        <p className="mb-4 font-bold">Registrierung über die DropFriends-App</p>

        <p className="mb-4">
          Um selbst DropPoint zu werden und somit eine Vergütung zur Verwaltung von Sendungen zu
          erhalten, ist eine Registrierung über die App DropFriends notwendig: Erhältlich im
          Google-Play-Store und App-Store.
        </p>

        <p className="mb-4">
          Bist Du erst registriert, dann wechsel in der App auf den Menüpunkt "DropPoint". Dort
          kannst Du alle Angaben nach Deinen Vorstellungen machen. Ganz einfach und schnell: Unter
          zwei Minuten.
        </p>

        <p className="mb-4 font-bold">Angabe der DropPoint-Adresse und der Abholzeiten</p>

        <p className="mb-4">
          Bestimme dort, neben dem Standort Deines DropPoints, Deine individuellen Abholzeiten. So
          stellst Du sicher, dass Sendungen auch nur in diesen Zeitrahmen von Deiner Community bei
          Dir abgeholt werden.
        </p>

        <p className="mb-4 font-bold">Angabe von Größe und Gewicht von Sendungen</p>

        <p className="mb-4">
          Auch die Größe und das Gewicht von einzelnen Pakete innerhalb einer Sendung kannst Du nach
          Deinen Wünschen angeben. So ist bereits vor der Bestellung von Online-Einkäufen für jeden
          klar ersichtlich zu welchen Bedingungen Du Pakete annehmen möchtest. Schließlich sind
          Deine Privat- oder Gewerberäume heilig und das sollen sie auch bleiben.
        </p>

        <p className="mb-4 font-bold">Angabe der Anzahl von Sendungen</p>

        <p className="mb-4">
          Selbstverständlich kannst Du auch frei bestimmen, welche Anzahl von Sendungen Du
          gleichzeitig verwalten möchtest. Hast Du die Gesamtzahl von Sendungen vom Paketboten
          angenommen, dann ist Dein DropPoint automatisch so lange nicht mehr buchbar, bis Du min.
          eine Sendung an den entsprechenden Eigentümer übergeben hast. So respektieren wir Deine
          Wünsche und schützen Deine Kapazitäten. Und spielt für Dich die Anzahl von Sendungen keine
          Rolle, dann ist auch diese Auswahl möglich.
        </p>

        <p className="mb-4 font-bold">
          Angabe Deines Profilbilds und Aktivierung Deines DropPoints
        </p>

        <p className="mb-4">
          Zuletzt wird nur noch ein Selfie mit Deinem lächelnden Gesicht benötigt. So weiß Deine
          Community, welcher freundliche DropPoint Sendungen für sie verwaltet. Das gibt Sicherheit,
          hilft bei der Identifizierung und ein Lächeln freut uns sowieso Alle.
        </p>

        <p className="mb-4">
          Im Anschluß kannst Du Deinen DropPoint mit einem einzigen Fingertipp aktivieren: In
          Echtzeit.
        </p>

        <p className="mb-4">
          Bist Du erst als DropPoint aktiviert, dann bist Du für die Community sofort sichtbar und
          Du kannst umgehend Buchungen erhalten. Solltest Du einmal wichtige Termine haben, die Dich
          für zukünftige Buchungen einschränken würden, dann deaktiviere Deinen DropPoint einfach
          mit einem weiteren Fingertipp. Du wirst dann keine weiteren Buchungen mehr erhalten. Keine
          Kündigungsfristen.
        </p>

        <p className="mb-4">
          Beachte aber, dass Sendungen, welche bereits auf dem Weg zu Dir sind natürlich noch von
          Dir entegegengenommen und an den Eigentümer übergeben werden müssen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wieviel kann ich mit DropFriends verdienen?">
        <SubTitle tw="mt-0">1. Vergütung pro Sendung</SubTitle>
        <p className="mb-4">
          DropFriends verfolgt das Prinzip eines dynamischen Vergütungsmodells. So werden DropPoints
          mit positiven Bewertungen, gemäß der AGB, mit einer Steigerung der Vergütung pro Sendung
          belohnt. Pro erfolgreich übergebener Sendung erhältst Du als DropPoint somit stets eine
          faire Vergütung.
        </p>
        <p>00 - 14 positive Bewertungen: 0,50 € pro Sendung</p>
        <p>15 - 49 positive Bewertungen: 0,60 € pro Sendung</p>
        <p>50 - 79 positive Bewertungen: 0,70 € pro Sendung</p>
        <p>80 - 99 positive Bewertungen: 0,80 € pro Sendung</p>
        <p>100 - 149 positive Bewertungen: 0,90 € pro Sendung</p>
        <p>ab 150 positive Bewertungen: 1,00 € pro Sendung </p>
        <SubTitle>{'2. Paketaufbewahrung > 24 Stunden'}</SubTitle>
        <p className="mb-4">
          Als Paketeigentümer profitiert man davon, dass Sendungen stets beim gebuchten DropPoint
          zustellbar sind und somit tagesgleich nach Erstzustellung vom Sendungseigentümer abgeholt
          werden können.
        </p>
        <p className="mb-4">
          Endlich verpasst man nie wieder den Paketboten und kann die Dinge die man liebt so schnell
          wie möglich auspacken und in Händen halten. Dafür liegen die Sendungen ohne weitere Kosten
          für die ersten 24 Stunden nach Erstzustellung bei den DropPoints für den
          Sendungseigentümer zur Abholung bereit.
        </p>
        <p className="mb-4">
          Selbstverständlich besteht aber auch die Möglichkeit, dass angenommene Pakete entgeltlich,
          beim entsprechenden DropPoint bis zu 120 Stunden (5 Tage), sicher aufbewahrt werden
          können. So erhalten DropPoints eine zusätzliche Vergütung.
        </p>
        <p className="mb-4">
          Für die in Anspruch genommene Kapazität des DropPoints wird je eine 24-Stunden-Pauschale
          vergütet.
        </p>
        <p>Paketaufbewahrungszeit von 48 Stunden: 0,50 € pro Paket</p>
        <p>Paketaufbewahrungszeit von 72 Stunden: 0,50 € pro Paket</p>
        <p>Paketaufbewahrungszeit von 96 Stunden: 1,50 € pro Paket</p>
        <p>Paketaufbewahrungszeit von 120 Stunden: 1,50 € pro Paket</p>
        <p className="mt-4">
          Wird ein Paket also nach 70 Stunden beim DropPoint abgeholt, dann beträgt die
          Sondervergütung insgesamt 1,00 €.
        </p>
        <SubTitle>3. Trinkgelder</SubTitle>
        <p className="mb-4">
          Beliebte DropPoints helfen der Community viel Zeit und Nerven zu sparen. Da ist es nur
          selbstverständlich, dass Sendungseigentümer sich besonders für die Hilfe erkenntlich
          zeigen möchten; besonders an Feiertagen und Ferienzeiten.
        </p>
        <p>
          Deswegen gibt es nach jeder erfolgreichen Sendungsübergabe die Möglichkeit ein Trinkgeld
          über die App zu erhalten.
        </p>
        <SubTitle>4. Auszahlung der Gesamtvergütung</SubTitle>
        <p className="mb-4">
          Deine einzelnen Vergütungen werden in Zusammenarbeit mit unserem Zahlungsdienstleister auf
          ein für Dich seperat angelegtes Verwaltungskonto gesammelt und zum Ende des jeweiligen
          Monats in Summe auf Dein hinterlegtes Auszahlungsmittel (Dein Bankkonto) ausgeschüttet.
        </p>
        <p className="mb-4">
          So kannst Du sicher sein, dass Du im Laufe der ersten Woche des Folgemonats eine
          DropFriends-Gutschrift Deiner monatlichen Gesamtvergütung auf Deinem Bankkonto vorfindest.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Muss ich den ganzen Tag zu Hause bleiben, um Sendungen zu übergeben?">
        <p className="mb-4">
          Als DropPoint steht es Dir frei selbst zu bestimmen wann Du Sendungen an Deine DropFriends
          übergeben möchtest. So kannst Du Deinen Alltag nach wie vor frei planen und Deine
          Community wird bereits vor der Buchung Deines DropPoints über die möglichen Abholzeiträume
          informiert.
        </p>
        <p className="mb-4">
          So kann jeder Sendungseigentümer für sich selbst entscheiden, welche Abholzeiträume für
          seinen Alltag am geeignetsten sind und sich somit den DropPoint buchen, der seinen
          Anforderungen am Besten entspricht.
        </p>
        <p className="mb-4">
          Natürlich kannst Du frei bestimmen, ob Du Übergaben lieber am Morgen, Mittag, Nachmittag,
          Abend oder gar in den Nachtstunden durchführen möchtest. Auch ist es für Dich möglich
          mehrere Abholzeiträume pro Tag zu definieren.
        </p>
        <p className="mb-4">
          Und sind Deine angegebenen Abholzeiten einmal überholt, dann passe sie Deinen täglichen
          Gegebenheiten in Sekundenschnelle an. So bleibst Du auch weiterhin flexibel und kannst
          kommende Sendungen trotzdem erfolgreich übergeben.
        </p>
        <p className="mb-4">
          Beachte aber, dass bereits durchgeführte Buchungen von den Abholzeiten ausgehen, welche Du
          zum Buchungszeitpunkt für Deine Community veröffentlicht hattest.
        </p>
        <p className="mb-4">
          <strong>Tipp:</strong> Abholzeiten zwischen 19 Uhr und 24 Uhr sind besonders bei der
          DropFriends-Community beliebt. Erweitere Deine Abholzeiten entsprechend, um noch mehr
          Buchungen zu erhalten und somit Deine monatliche Vergütung zu erhöhen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Der Abholer möchte mir ein Trinkgeld geben. Ist das möglich?">
        <p className="mb-4">
          Beliebte DropPoints helfen der Community viel Zeit und Nerven zu sparen. Da ist es nur
          selbstverständlich, dass Sendungseigentümer sich besonders für die Hilfe erkenntlich
          zeigen möchten; besonders an Feiertagen und Ferienzeiten.
        </p>
        <p className="mb-4">
          Deswegen gibt es nach jeder erfolgreichen Sendungsübergabe die Möglichkeit ein Trinkgeld
          zu erhalten.
        </p>
        <p className="mb-4">
          Hierzu rufen Deine Community-Mitglieder den Menüpunkt "Meine Pakete" auf, um die
          entsprechenden Buchungen per Fingertipp zu öffnen.
        </p>
        <p className="mb-4">
          Unter der Kategorie "Trinkgeld & Bewertung" wählt Dein DropFriend den gewünschten Betrag
          aus und kann im gleichen Atemzug noch eine positive Bewertung für Dich hinterlassen.
          Positive Bewertungen helfen Dir dabei, dass Du Deinem Ziel eines
          Vergütungsschwellenaufstiegs schnell näher kommst.
        </p>
        <p className="mb-4">
          Die freiwillige Zahlung des Trinkgelds wird per Fingertipp von Deinem DropFriend ausgelöst
          und bestätigt. Die Auszahlung erfolgt dann gemeinsam mit Deinen gesammelten monatlichen
          Sendungsvergütungen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wann bekomme ich meine Vergütung ausgezahlt?">
        <p className="mb-4">
          Pro erfolgreich übergebener Sendung erhälst Du als DropPoint eine faire Vergütung. Zudem
          hast Du die Möglichkeit Trinkgelder pro Sendung über die DropFriends-App einzusammeln.
        </p>
        <p className="mb-4">
          Und sollten Deine DropFriends eine Paketaufbewahrung von z.B. bis zu 5 Tagen in Anspruch
          nehmen, dann erhälst Du zusätzliche Vergütungen pro Paket. Toll, oder?
        </p>
        <p className="mb-4">
          Deine einzelnen Vergütungen werden in Zusammenarbeit mit unserem Zahlungsdienstleister auf
          ein für Dich seperat angelegtes Verwaltungskonto gesammelt und zum Ende des jeweiligen
          Monats auf Dein hinterlegtes Auszahlungsmittel (Dein Bankkonto) ausgeschüttet.
        </p>
        <p className="mb-4">
          So kannst Du sicher sein, dass Du im Laufe der ersten Woche des Folgemonats eine
          DropFriends-Gutschrift Deiner monatlichen Gesamtvergütung auf Deinem Bankkonto vorfindest.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wieviele Sendungen muss ich annehmen?">
        <p className="mb-4">
          Als DropPoint steht es Dir frei selbst zu bestimmen wie viele Sendungen Du gleichzeitig
          für Deine Community vorhalten möchtest. Egal ob Du nur eine Sendung aufbewahren magst oder
          Dich die Sendungsanzahl nicht weiter interessiert. Bei DropFriends hast Du die freie Wahl.
        </p>
        <p className="mb-4">
          Und ist Deine angegebene Kapazität tatsächlich einmal ausgeschöpft, sind keine weiteren
          Buchungen Deines DropPoints mehr möglich, bis mindestens eine der Sendungen erfolgreich an
          den Eigentümer übergeben worden ist. So stellt DropFriends die Einhaltung Deiner Wünsche
          sicher.
        </p>
        <p className="mb-4">
          Sobald einer Deiner DropFriends Pakete an Dich verschicken lässt und der Lieferbote die
          entsprechenden Bestellungen an Dich übergeben möchte, ist eine Annahme von Dir natürlich
          gewünscht. Überprüfe daher stets vor der Annahme von Sendungen, ob sich auf dem
          Versandetikett auch ein gültiger DropFriends- Buchungscode befindet.
        </p>
        <p className="mb-4">
          Heißt: Wurde Dein DropPoint erfolgreich von einem Deiner DropFriends gebucht, dann werden
          Sendungen berechtigt bei Dir zugestellt und Du kannst die Annahme selbiger vornehmen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie kann ich meine privaten Pakete von DropFriends-Paketen unterscheiden?">
        <p className="mb-4">
          Jede DropFriends-Sendung hat ihren eigenen individuellen Buchungscode. Entsprechend
          findest Du selbigen auch stets auf dem Versandetikett der jeweiligen Pakete.
        </p>
        <p className="mb-4">
          Der Code besteht aus einer 6-stelligen Buchstaben-/Zahlenkombination und wird von dem
          Zusatz "FUER-NACHNAME-" begleitet. Beispiel: <strong>"FUER-MUSTERMANN-1C23BA".</strong>
        </p>
        <p className="mb-4">
          So ist gut für Dich sichtbar gekennzeichnet, dass es sich bei dem Paket um eine
          DropFriends-Buchung handelt. So kommt es nicht zu versehentlichen Verwechslungen mit
          Deinen eigenen persönlichen Sendungen.
        </p>
        <p className="mb-4">
          Heißt: Wurde Dein DropPoint erfolgreich von einem Deiner DropFriends gebucht, dann werden
          Sendungen berechtigt bei Dir zugestellt und Du kannst die Annahme selbiger vornehmen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie nehme ich Pakete an?">
        <p className="mb-6">
          Wurde Dein DropPoint zur Annahme von Sendungen gebucht, dann erhältst Du umgehend eine
          Benachrichtigung von DropFriends. So weißt Du direkt, dass Pakete auf dem Weg zu Dir sind
          und der Paketbote schon bald bei Dir klingeln wird.
        </p>

        <SubTitle>1. Schritt: Tür öffnen und Buchungscode abgleichen</SubTitle>
        <p className="mb-4">
          Achte bei der Annahme von Paketen durch den Lieferanten auf den individuellen Buchungscode
          auf dem Versandetikett.
        </p>

        <p className="mb-4">
          Dieser kann beispielsweise für den Sendungseigentümer "Max Mustermann" wie folgt aussehen:
        </p>
        <strong className="mb-4 block">FUER-MUSTERMANN-85d4ff</strong>

        <p className="mb-4">
          Er beinhaltet stets den Nachnamen des Paketeigentümers, gefolgt von einer einmaligen
          sechsstelligen Buchstaben-Zahlen-Kombination.
        </p>

        <p className="mb-6">
          So ist gut für Dich sichtbar gekennzeichnet, dass es sich bei dem Paket um eine
          DropFriends-Buchung handelt. Weiterhin kommt es nicht zu versehentlichen Verwechslungen
          mit Deinen eigenen persönlichen Sendungen.
        </p>

        <SubTitle>2. Schritt: Foto von der Sendung schießen</SubTitle>
        <p className="mb-4">
          Der Sendungscode ist auch in der DropFriends-App für Dich unter dem Menupunkt "DropPoint"
          ersichtlich.
        </p>

        <p className="mb-4">
          Um die Annahme von Sendungen in der App vorzunehmen, öffnest Du dort die entsprechende
          Buchung.
        </p>

        <p className="mb-4">
          Die DropFriends-App wird dann die Kamerafunktion Deines Handys aktivieren und Dich
          auffordern bis zu drei Bilder von dem gelieferten Paket zu schießen. Dann hast Du die
          Möglichkeit alle notwendigen Details von Paketen zu dokumentieren.
        </p>

        <p className="mb-4">
          Achte hierbei darauf, dass man das Paket gut erkennt und immer nur ein Paket auf den Fotos
          zu sehen sind.
        </p>

        <p className="mb-4">
          Auch einen kurzen Text zu dem jeweiligen Paket kannst Du hinterlassen. Die Bilder und
          Texte sind nur für DropFriends, für den Sendungseigentümer und für Dich ersichtlich. Alle
          Parteien sind dann über die Beschaffenheit der Sendung zum Lieferzeitpunkt im Bilde.
        </p>

        <p className="mb-4">
          Solltest Du für die selbe Sendung ein weiteres Paket erhalten, dann tippe einfach auf den
          Knopf "Weiteres Paket hinzufügen". So behälst Du und der Sendungseigentümer den Überblick.
        </p>

        <p className="mb-4">
          Im Anschluß bestätigst Du Deine Angaben mit einem Antipper auf den Knopf "Paket annehmen".
        </p>
      </FAQAccordion>
      <FAQAccordion question="Ein Paket ist beschädigt. Was muss ich tun?">
        <p className="mb-4">
          Auf dem Transportweg von Sendungen kann es immer zu Beschädigungen des Inhalts oder der
          Kartonage kommen.
        </p>
        <p className="mb-4">
          Übergibt Dir der Paketbote eine Sendung, dann bestätigst Du die Annahme des Paketes mit
          Hilfe Deiner Handykamera. Pro Paket hast Du die Möglichkeit bis zu drei Fotos zu
          hinterlegen.
        </p>
        <p className="mb-4">
          Dokumentiere sichtbare Beschädigungen der Verpackung für Deinen DropFriend. Zudem kannst
          Du noch zusätzliche Infos in Textform abgeben. So ist der Sendungseigentümer in Echtzeit
          informiert und erlebt keine Überraschung hinterher.
        </p>
        <p className="mb-4">
          Zudem kannst Du mit einer genauen Dokumentation festhalten, dass eine Beschädigung der
          Verpackung bereits bei der Übergabe vorhanden war und nicht versehentlich nachträglich
          erfolgte.
        </p>
        <p className="mb-4">
          Ob ein Schaden allerdings auch innerhalb des Paketes vorliegt lässt sich für Dich nicht
          ermitteln. Das Öffnen von Sendungen ist DropPoints stets untersagt.
        </p>
        <p className="mb-4">
          Stellt der Paketeigentümer nachträglich fest, dass der Inhalt seiner Sendung beschädigt
          ist, dann muss er dies direkt beim Versender reklamieren.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Der Paketbote hat nicht bei mir geklingelt. Wie bekomme ich die Sendung für meinen DropFriend?">
        <p className="mb-4">
          Leider kommt es immer wieder vor, dass Paketboten Sendungen nicht bei der Lieferadresse
          übergeben, obwohl man anzutreffen ist. Das ist ärgerlich.
        </p>
        <p className="mb-4">
          Als DropPoint bist Du als Lieferadresse auf dem Paket vermerkt. Somit wirst Du eine
          Benachrichtigung vom Lieferdienst erhalten, wenn Sendungen z.B. am Folgetag in einer
          Filiale von Dir abgeholt werden können.
        </p>
        <p className="mb-4">
          Sobald man Dir die Sendung übergeben hat, kannst Du Deinen DropFriend wie gewohnt per
          Paketfoto über die Ankunft informieren.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Was ist der Unterschied zwischen einer Sendung und einem Paket?">
        <p className="mb-4">
          DropFriends unterscheidet auf der Online-Plattform zwischen
          <br />- Sendungen und
          <br />- Paketen.
        </p>
        <p className="mb-4">
          Jeder kennt das: Man bestellt sich in seinem Lieblings- Online-Shop z.B. sein
          Wunsch-Outfit. Dazu gehört ein neuer Pullover, tolle neue Schuhe und die passenden Jeans
          in zweifacher Ausführung.
        </p>
        <p className="mb-4">
          Das Problem: Selten kann man als Online-Shopper vorhersagen, wieviele Pakete nun
          tatsächlich in den Versand gegeben werden. So ist es in unserem Beispiel möglich, dass
          lediglich die zwei Jeans in einem Karton verschickt werden. Der Rest wird in einzelnen
          Versandstücken transportiert.
        </p>
        <p className="mb-4">
          Das würde bedeuten, dass unsere Bestellung in drei verschiedenen Paketen auf den Weg
          geschickt worden ist. Doch woher soll das der Online-Shopper vor der Buchung eines
          DropPoints wissen?
        </p>
        <p className="mb-4">
          Diese eine Bestellung von vielen verschiedenen Dingen, wird von DropFriends als eine
          Sendung definiert, welche insgesamt drei Pakete beinhalten darf. Somit wird der selbe
          DropFriends-Buchungscode auf allen drei Paketen geführt.
        </p>
        <p className="mb-4">
          Die Annahme der einzelnen Pakete durch einen DropPoint erfolgt wie gewohnt mit min. einem
          Foto pro Paket. Die DropFriends-App macht die Verwaltung der drei Pakete in der gebuchten
          Sendung kinderleicht und übernimmt die volle Kommunikation zum Sendungseigentümer
          vollautomatisch.
        </p>
        <p className="mb-4">
          Die DropFriends-App ermöglicht die Verwaltung von bis zu drei Paketen innerhalb einer
          Sendung. Sollte die maximale Versandstückanzahl pro Sendung überstiegen werden, dann
          kontaktiere bitte nach Annahme umgehend DropFriends. Gerne helfen wir bei der korrigierten
          Paketanzahlverwaltung.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Darf ich Sendungen einfach so an den Abholer übergeben?">
        <p className="mb-4">
          Sendungseigentümer werden zu den von Dir angegebenen Abholzeiten bei Dir klingeln, um die
          entsprechenden Sendungen von Dir abzuholen. Jede Sendung hat ihren eigenen individuellen
          Buchungscode.
        </p>
        <p className="mb-4">
          Ein guter DropFriend stellt sich mit seinem Namen vor und wird Dir den
          DropFriends-Buchungscode nennen oder gar auf seinem Mobiltelefon zeigen. Der Code besteht
          aus einer 6-stelligen Buchstaben-/Zahlenkombination und wird von dem Zusatz
          "FUER-NACHNAME-" begleitet. Beispiel:
          <strong>"FUER-MUSTERMANN-1C23BA"</strong>
        </p>
        <p className="mb-4">
          So ist gut für Dich sichtbar gekennzeichnet, dass es sich bei dem Paket um eine
          DropFriends-Buchung handelt. So kommt es nicht zu versehentlichen Verwechslungen mit
          Deinen eigenen persönlichen Sendungen.
        </p>
        <p className="mb-4">
          Gib nun den 6-stelligen Buchungscode in der Suchmaske der DropFriends-App ein und tippe
          auf die Buchung des Abholers, um den Übergabeprozess zu starten.
        </p>
        <p className="mb-4">
          Folge den einfachen Anweisungen auf Deinem Bildschirm. Die DropFriends-App wird Dir bei
          der Identifizierung des Sendungseigentürmers unter die Arme greifen. So kannst Du stets
          sicher sein, dass auch nur die Person Sendungen erhält, die auch tatsächlich für sie
          vorgesehen sind.
        </p>
        <p className="mb-4">
          Mehr zum Thema "Sendungsübergabe" kannst Du unter "Wie kann ich den Sendungseigentümer
          identifizieren?" einsehen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Ich habe eine Sendung angenommen. Was muss ich tun, wenn die Sendung nicht vom Eigentümer abgeholt wird?">
        <p className="mb-4">
          Für den unwahrscheinlichen Fall, dass bei Dir aufbewahrte Sendungen nicht vom Eigentümer
          abgeholt werden, besteht für Dich die Möglichkeit eine Sendungsweiterleitung direkt an
          DropFriends zu starten.
        </p>
        <p className="mb-4">
          So ist sichergestellt, dass Deine Kapazitäten nicht unnötig belastet werden und
          DropFriends kümmert sich um die Sendung.
        </p>
        <SubTitle>Wann kann ich eine Sendungsweiterleitung starten?</SubTitle>
        <p className="mb-4">
          Der maximale Aufbewahrungszeitraum für DropPoints beträgt fünf Tage (=120 Stunden). Wurde
          nach Ablauf des fünften Tages eine Sendung noch immer nicht abgeholt, besteht die
          Möglichkeit den Kundendienst zu kontaktieren. Gemeinsam wird dann die
          Sendungsweiterleitung gestartet.
        </p>
        <p className="mb-4">
          Selbstverständlich erhältst Du trotzdem Deine volle Vergütung für die Buchung und auch für
          den Paketaufbewahrungszeitraum. Die Auszahlung erfolgt monatlich auf das von Dir
          hinterlegte Auszahlungsmittel.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Was sind Vergütungsschwellen?">
        <p className="mb-4">
          DropFriends verfolgt das Prinzip eines dynamischen Vergütungsmodells. So werden DropPoints
          mit positiven Bewertungen, gemäß der AGB, mit einer Steigerung der Vergütung pro Sendung
          belohnt.
        </p>
        <p className="mb-4">
          Das bedeutet aber auch, dass negative Bewertungen von Nutzern dazu führen, dass man
          innerhalb der Vergütungsschwellen wieder absteigen kann. Denn: Jede negative Bewertung
          negiert eine positive Bewertung.
        </p>
        <p className="mb-4">
          Beispiel: <br />
          Du hast als DropPoint insgesamt 50 Bewertungen.
        </p>
        <p className="mb-4">
          Alle 50 Bewertungen waren bisher positiv. Somit liegt Dein DropPoint bei der
          Vergütungsschwelle 2 (50 positive Bewertungen).
        </p>
        <p className="mb-4">Nun erhälst Du eine 51. Bewertung. Diese fällt leider negativ aus.</p>
        <p className="mb-4">
          Weil negative Bewertungen positive Bewertungen negieren, bleiben nun bei insgesamt 51
          Feedbacks noch 49 positive Bewertungen übrig. Somit fällt Dein DropPoint von der
          Vergütungsschwelle 2 wieder zurück auf die Vergütungsschwelle 1. Mehr zu den
          unterschiedlichen Vergütungsschwellen kannst Du unter "Wieviel kann ich mit DropFriends
          verdienen?" einsehen.
        </p>
        <SubTitle>
          Kann ich nach negativen Bewertungen wieder in den Vergütungsschwellen aufsteigen, um meine
          Vergütung pro Sendung zu steigern?
        </SubTitle>
        <p className="mb-4">
          Selbstverständlich. In dem oben dargestellten Beispiel führt eine weitere positive
          Bewertung wieder dazu, dass die Vergütungsschwelle 2 erreicht wird und die Vergütung pro
          Sendung steigt automatisch.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Was sind Paketaufbewahrungskonditionen?">
        <p className="mb-4">
          Als DropFriends-Nutzer profitiert man davon, dass Sendungen stets bei dem gebuchten
          DropPoint zustellbar sind und somit tagesgleich nach Erstzustellung vom Sendungseigentümer
          abgeholt werden können.
        </p>
        <p className="mb-4">
          Endlich verpasst man nie wieder den Paketboten und kann die Dinge die man liebt so schnell
          wie möglich auspacken und in Händen halten. Dafür liegen die Sendungen ohne weitere Kosten
          für die ersten 24 Stunden nach Erstzustellung beim gebuchten DropPoint für den
          Sendungseigentümer zur Abholung bereit.
        </p>
        <p className="mb-4">
          Selbstverständlich besteht aber auch die Möglichkeit, dass angenommene Pakete entgeltlich,
          bei dem entsprechenden DropPoint bis zu 120 Stunden (5 Tage), sicher aufbewahrt werden
          können.
        </p>
        <p className="mb-4">
          Für die in Anspruch genommene Kapazität des DropPoints wird je eine 24-Std-Pauschale,
          gemäß den AGB, erhoben.
        </p>
        <p className="mb-4">
          Wird ein Paket also nach z.B. 70 Stunden beim DropPoint vom Sendungseigentümer in Empfang
          genommen, dann werden selbigen zwei 24-Std-Pauschalen in Rechnung gestellt. Mehr zu den
          Paketaufbewahrungskonditionen kannst Du unter "Wieviel kann ich mit DropFriends
          verdienen?" einsehen.
        </p>
        <p className="mb-4">
          Wird eine Abholung von Paketen innerhalb der benannten 120 Stunden versäumt, ist der
          DropPoint berechtigt, die entsprechenden Pakete an DropFriends weiterzuleiten, um die in
          Anspruch genommenen Kapazitäten wieder für weitere Buchungen freizugeben.
        </p>
        <p className="mb-4">
          Kosten die durch die Weiterleitung entstehen können, werden dem Sendungseigentümer von
          DropFriends in Rechnung gestellt. Eine weitere Kommunikation zur Übergabe der betroffenen
          Pakete erfolgt dann direkt durch DropFriends.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie kann ich den Sendungseigentümer an meiner Haustür identifizieren?">
        <p className="mb-4">
          Die Identifizierung von Sendungsabholern ist essentiell für die Übergabe von Paketen an
          Deiner Haustür, denn Du möchtest sicherlich nur den tatsächlichen Eigentümern Sendungen
          übergeben.
        </p>
        <p className="mb-4">
          Eine Identifizierung ist rasch erledigt und die DropFriends-App unterstützt Dich hierbei
          Schritt für Schritt.
        </p>
        <p className="mb-4">
          Beachte: Als DropPoint kannst Du Deine Kriterien zur Identifizierung unter dem Menupunkt
          "DropPoint" selbst bestimmen. So besteht die Möglichkeit eine Prüfung von Buchungscode und
          Foto des Paketeigentümers anzugeben oder sogar einen zusätzlichen Abgleich per
          Lichtbildausweis durchzuführen.
        </p>
        <SubTitle>1. Schritt: Identifizierung per Buchungscode</SubTitle>
        <p className="mb-4">
          Sobald einer Deiner DropFriends Sendungen zu den von Dir angegebenen Abholzeiten bei Dir
          abholen möchte, öffnest Du freundlich die Tür und fragst nach dem eindeutigen
          Buchungscode.
        </p>
        <p className="mb-4">
          Dieser kann beispielsweise für den Sendungseigentümer "Max Mustermann" wie folgt aussehen:
          <br />
          <strong>FUER-MUSTERMANN-85d4ff</strong>
          <br />
        </p>
        <p className="mb-4">
          Der Buchungscode befindet sich auf dem Versandetikett und ist auch in der DropFriends-App
          für Dich, sowie für den Paketeigentümer ersichtlich. Auch in der Buchungsbestätigung, die
          wir Euch Beiden per E-Mail zugeschickt haben, könnt Ihr den Code einsehen.
        </p>
        <p className="mb-4">
          Er beinhaltet stets den Nachnamen des Paketeigentümers, gefolgt von einer einmaligen
          sechsstelligen Buchstaben-Zahlen-Kombination.
        </p>
        <p className="mb-4">
          Konnte Dir der Abholer den korrekten Buchungscode nennen, dann kannst Du die Buchung in
          Deiner DropFriends-App unter dem Menupunkt "DropPoints" ausfindig machen.
        </p>
        <SubTitle>2. Schritt: Fotoabgleich</SubTitle>
        <p className="mb-4">
          Öffne nun die Buchung und starte die Paketübergabe per Fingertipp auf den grünen Knopf
          "Paket übergeben". Im Vorgang erhälst Du verschiedene Anweisungen. Du kannst also nichts
          falsch machen.
        </p>
        <p className="mb-4">
          Darunter auch: "Vergleiche das Foto mit dem Abholer des Pakets." Die DropFriends-App zeigt
          Dir das registrierte Bild des Sendungseigentümers an. Vergleiche es mit dem Abholer und Du
          bist auf der sicheren Seite.
        </p>
        <SubTitle>3. Schritt: Kontrolle eines Lichtbildausweises</SubTitle>
        <p className="mb-4">
          Hast Du als DropPoint angegeben, dass Du stets eine Identifizierung per Lichtbildausweis
          wünschst, dann wirst Du nach erfolgreichem Abgleich des Fotos aufgefordert nach dem
          Ausweis zu fragen.
        </p>
        <p className="mb-4">
          Im Anschluß werden Dir drei Schritte aufgezeigt, die Du für eine erfolgreiche Prüfung
          vollziehen solltest.
        </p>
        <p className="mb-4">
          Stimmten auch hier alle Daten, dann kannst Du beruhigt die Sendung an den Abholer
          übergeben.
        </p>
        <p className="mb-4">
          Wie Du siehst, führt Dich die DropFriends-App ganz einfach und schnell durch den sicheren
          Identifizierungsprozess.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie kann ich den Abholer bewerten?">
        <p className="mb-4">
          Nachdem Du dem Sendungseigentümer seine Sendungen erfolgreich übergeben hast, fordert Dich
          die DropFriends-App einmalig dazu auf, den Abholer zu bewerten.
        </p>
        <p className="mb-4">
          Anhand Deiner Sterneangabe und einem optionalen Bewertungstext, hinterlässt Du dem
          DropFriends-Team ein wichtiges Feedback. Teile mit, ob der Sendungseigentümer freundlich
          und respektvoll war.
        </p>
        <p className="mb-4">
          Hinterlasse eine Nachricht darüber, wie zufrieden Du mit dem Abholer warst, damit wir eine
          angenehme Atmosphäre für alle DropFriends-Nutzer erhalten können.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie kann ich in der App nach Sendungen suchen?">
        <p className="mb-4">
          Jede Sendung hat ihren eigenen individuellen Buchungscode. Entsprechend findest Du
          selbigen auch stets auf dem Versandetikett der jeweiligen Pakete.
        </p>
        <p className="mb-4">
          Der Code besteht aus einer 6-stelligen Buchstaben-/Zahlenkombination und wird von dem
          Zusatz "FUER-NACHNAME-" begleitet. Beispiel: "FUER-MUSTERMANN-1C23BA".
        </p>
        <p className="mb-4">
          So ist gut für Dich sichtbar gekennzeichnet, dass es sich bei dem Paket um eine
          DropFriends-Buchung handelt. So kommt es nicht zu versehentlichen Verwechslungen mit
          Deinen eigenen persönlichen Sendungen.
        </p>
        <p className="mb-4">
          Ein guter DropFriend stellt sich zudem beispielsweise bei der Abholung seines Eigentums
          mit seinem Namen bei Dir vor und wird Dir den DropFriends-Sendungscode nennen oder gar auf
          seinem Mobiltelefon zeigen. Das dient der Identifizierung von Sendungseigentümern und
          natürlich der korrekten Pakete.
        </p>
        <p className="mb-4">
          Buchungen in der DropFriends-App finden: Solltest Du Sendungen nicht direkt in der App
          finden, dann gib den 6-stelligen Sendungscode in der Suchmaske unter dem Menupunkt
          "DropPoint" ein.
        </p>
        <p className="mb-4">
          Prüfe auch, ob Deine "Filtereinstellungen" neben dem Suchfeld korrekt eingestellt sind.
          Werden z.B. alle bereits abgeholten Sendungen über die Filter ausgeblendet, dann werden
          Dir keine Suchergebnisse, trotz korrekter Eingabe des 6-stelligen Buchungscodes,
          angezeigt.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Warum bekomme ich keine Buchungen?">
        <p className="mb-4">
          Als DropPoint hast Du die Möglichkeit absolut selbstbestimmmt über Deine Tätigkeit zu
          entscheiden.
        </p>
        <p className="mb-4">
          So kannst Du in der DropFriends-App unter dem Menüpunkt "DropPoint" in Deinen
          DropPoint-Einstellungen selbst bestimmen:
          <br />
          <br />- wieviele Sendungen Du gleichzeitig für Deine Community vorhalten möchtest,
          <br />
          <br />- wie schwer die einzelnen Pakete einer Sendung sein dürfen,
          <br />
          <br />- wie groß die einzelnen Pakete einer Sendung sein können
          <br />
          <br />- und wann die Sendungseigentümer ihre Bestellungen bei Dir abholen dürfen.
        </p>
        <p className="mb-4">
          Zudem ist es jedem DropPoint möglich sich auf aktiv oder inaktiv zu setzen. Mit diesem
          Schalter wird also bestimmt, ob man als DropPoint auf der DropFriends-Karte sichtbar ist
          oder eben nicht.
        </p>
        <SubTitle>Bietest Du attraktive Abholzeiten an?</SubTitle>
        <p className="mb-4">
          Als DropPoint bist Du genau dann für den Paketboten erreichbar, wenn Deine Community es
          nicht sein kann. Doch möchten die Sendungseigentümer ihre Pakete auch zu für sie in den
          Alltag passenden Zeiträumen abholen können.
        </p>
        <p className="mb-4">
          Prüfe also, ob Deine Abholzeiten auch in solchen Zeiträumen liegen, die für Deine
          berufstätigen DropFriends auch wahrzunehmen sind. Vorzugsweise orientiert sich die
          Community bei der Abholung von Sendungen an die Abend- und Nachtstunden.
        </p>
        <p className="mb-4">
          Möchtest Du also die Anzahl Deiner Buchungen erhöhen, dann erweitere Deine Abholzeiten.
          Besonders beliebt sind Zeiträume zwischen 19 Uhr und 24 Uhr.
        </p>

        <SubTitle>Ist die Maximalanzahl von Sendungen erreicht?</SubTitle>
        <p className="mb-4">
          Prüfe bitte in Deinen DropPoint-Einstellungen, ob Du eine maximale Anzahl von Sendungen
          eingestellt hast.
        </p>
        <p className="mb-4">
          Gegebenenfalls hast Du ja bereits Sendungen für Deine Community angenommen, die Du aber
          noch nicht übergeben hast und noch für Deine DropFriends aufbewahrst.
        </p>
        <p className="mb-4">
          Falls Du die Gesamtanzahl bereits erreicht hast und weitere Buchungen wünschst, dann
          korrigiere Deine Angaben entsprechend.
        </p>
        <SubTitle>Ist Dein DropPoint aktiv?</SubTitle>
        <p className="mb-4">
          Bleiben Buchungen bei Dir aus, dann prüfe, ob Dein DropPoint aktiv geschaltet ist. Hierzu
          muss der Schalter in den DropPoint-Einstellungen grün dargestellt sein und der runde Knopf
          nach rechts zeigen.
        </p>
        <p className="mb-4">
          Ist dies nicht der Fall, dann aktiviere Deinen DropPoint in Sekundenschnelle mit einem
          Fingertipp auf den Schalter.
        </p>
        <p className="mb-4">
          Ist der runde Knopf zwar aktiviert, doch wird lediglich grau dargestellt, dann prüfe, ob
          es eine derzeitige Einschränkung Deines DropFriends-Kontos gibt.
        </p>
        <p className="mb-4">
          Einschränkungen resultieren aus einem nicht erreichbaren E-Mail-Postfach oder einem
          bestehenden Zahlungsrückstand.
        </p>
        <p className="mb-4">
          Auf dies weisen wir mit Hilfe einer gelben Box am Kopf der DropFriends-App hin.
        </p>
        <p className="mb-4">Bitte logge Dich in die App ein und folge den dortigen Anweisungen.</p>
      </FAQAccordion>
      <FAQAccordion question="Muss ich alle eintreffenden Sendungen annehmen?">
        <p className="mb-4">
          Als DropPoint steht es Dir frei selbst zu bestimmen wie viele Sendungen Du gleichzeitig
          für Deine Community vorhalten möchtest. Egal ob Du nur fünf Sendungen zur selben Zeit
          aufbewahren magst oder Dich die Sendungsanzahl nicht weiter interessiert - bei DropFriends
          hast Du die freie Wahl.
        </p>
        <p className="mb-4">
          Und ist Deine angegebene Kapazität tatsächlich einmal ausgeschöpft, dann sind keine
          weiteren Buchungen Deiner Annahmestelle mehr möglich, bis mindestens eine der Sendungen
          erfolgreich an den Eigentümer übergeben worden ist. So stellt DropFriends die Einhaltung
          Deiner Wünsche sicher.
        </p>
        <p className="mb-4">
          Sobald einer Deiner DropFriends Sendungen an Dich verschicken lässt und der Paketbote die
          entsprechenden Bestellungen an Dich übergeben möchte, ist eine Annahme von Dir natürlich
          gewünscht. Überprüfe daher stets vor der Annahme von Sendungen, ob sich auf dem
          Versandetikett auch ein gültiger DropFriends- Buchungscode befindet.
        </p>
        <p className="mb-4">
          Heißt: Wurde Dein DropPoint erfolgreich von einem Deiner DropFriends gebucht, dann werden
          Sendungen berechtigt bei Dir zugestellt und Du kannst die Annahme selbiger vornehmen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Warum ist meine Vergütung pro Sendung weniger als vorher?">
        <p className="mb-4">
          DropFriends verfolgt das Prinzip eines dynamischen Vergütungsmodells. So werden DropPoints
          mit positiven Bewertungen mit einer Steigerung der Vergütung pro Sendung belohnt
          (Vergütungssschwellen können unter §4.3 AGB eingesehen werden).
        </p>
        <p className="mb-4">
          Das bedeutet aber auch, dass negative Bewertungen von Nutzern dazu führen können, dass man
          innerhalb der Vergütungsschwellen wieder absteigen kann. Denn: Jede negative Bewertung
          negiert eine positive Bewertung.
        </p>
        <p className="mb-4">
          Beispiel:
          <br />
          Du hast als DropPoint insgesamt 50 Bewertungen.
        </p>
        <p className="mb-4">
          Alle 50 Bewertungen waren bisher positiv. Somit liegt Dein DropPoint bei der
          Vergütungsschwelle 2 (50 positive Bewertungen).
        </p>
        <p className="mb-4">Nun erhälst Du eine 51. Bewertung. Diese fällt leider negativ aus.</p>
        <p className="mb-4">
          Weil negative Bewertungen positive Bewertungen negieren, bleiben nun bei insgesamt 51
          Feedbacks noch 49 positive Bewertungen übrig. Somit fällt Dein DropPoint von der
          Vergütungsschwelle 2 wieder zurück auf Vergütungsschwelle 1.
        </p>
        <SubTitle>
          Kann ich nach negativen Bewertungen wieder in den Vergütungsschwellen aufsteigen, um meine
          Vergütung pro Sendung zu steigern?
        </SubTitle>
        <p className="mb-4">
          Selbstverständlich. In dem oben dargestellten Beispiel führt eine weitere positive
          Bewertung wieder dazu, dass die Vergütungsschwelle 2 erreicht wird und die Vergütung pro
          Sendung steigt automatisch.
        </p>
        <p className="mb-4">
          Mehr zu den unterschiedlichen Vergütungsschwellen kannst Du unter "Wieviel kann ich mit
          DropFriends verdienen?" einsehen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie bekomme ich meine Vergütung ausgezahlt?">
        <p className="mb-4">
          DropFriends vergütet Dir Deine Annahme von Sendungen für Deine Community. Das bedeutet: Du
          bekommst am Ende des jeweiligen Monats eine echte Geldauszahlung auf Dein Bankkonto.
          Selbiges kannst Du in Deiner DropFriends-App unter dem Menupunkt "Profil" hinterlegen.
          Tippe hierzu auf die Kategorie "Auszahlungsmittel" und ergänze die dortigen Angaben.
        </p>
        <p className="mb-4">
          Deine Vergütungen, Trinkgelder etc. werden in Zusammenarbeit mit unserem
          Zahlungsdienstleister auf ein für Dich seperat angelegtes Verwaltungskonto gesammelt. Dort
          ist es sicher und kann von keinem Dritten veräußert werden. Die Auszahlung findet
          monatlich statt.
        </p>
        <p className="mb-4">
          Gesetzlich gibt es im Zahlungsverkehr nämlich strickte und unüberwindbare Regeln. Dazu
          gehört auch, dass unser Zahlungsdienstleister einen Nachweis von Dir verlangen kann, um
          sicherzugehen, dass du auch wirklich du bist.
        </p>
        <p className="mb-4">
          In unserem Fall müssen Kunden einen Reisepass oder einen Personalausweis vorweisen, der
          durch unseren Zahlungsdienstleister überprüft wird.
        </p>
        <p className="mb-4">
          Ohne diese rechtlich vorgegebene Überprüfung, ist es leider nicht möglich eine Auszahlung
          Deiner bereits gesammelten Vergütungen durchzuführen. Diese Angabe ist aber erst
          notwendig, wenn unser Zahlungsdienstleister danach verlangt.
        </p>
        <p className="mb-4">
          Eine Auszahlung Deiner Gesamtvergütung werden wir nach erfolgreich hinterlegter
          Auszahlungsmittelregistrierung zum Ende des jeweiligen Monats anstoßen. So kannst Du
          sicher sein, dass Du im Laufe der ersten Woche des Folgemonats eine DropFriends-Gutschrift
          Deiner monatlichen Gesamtvergütung auf Deinem Bankkonto vorfindest.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wozu braucht DropFriends eine Umsatzsteuer-ID?">
        <p className="mb-4">
          Als DropPoint kann man bei DropFriends seine private Haushaltskasse oder seinen
          gewerblichen Gesamtumsatz einfach, schnell und unkompliziert aufbessern. Man erhält
          enstprechend eine monatliche Auszahlung seiner erarbeiteten Vergütung auf sein Bankkonto.
          Ein toller Nebenverdienst.
        </p>
        <p className="mb-4">
          In Deutschland sind Einkünfte erstmal steuerpflichtig. Egal ob als Privatperson,
          Kleinstunternehmer oder als Gewerbetreibender. Allerdings gibt es Steuerfreibeträge.
          Überschreitest Du diese nicht, dann fallen natürlich auch keine Steuern an.
        </p>
        <p className="mb-4">
          DropFriends empfiehlt Dir eine Prüfung Deiner Gesamteinkünfte und die Höhe der für Dich
          geltenden Steuerfreibeträge.
        </p>
        <p className="mb-4">
          Zu jeder DropPoint-Auszahlung erstellt Dir DropFriends einen Auszahlungsbeleg, der die
          Auszahlungssumme aufzeigt. Hast Du DropFriends unter dem Menupunkt "Profil" Deine
          Umsatzsteuer-ID angegeben, dann wirst Du selbige auch auf Deinem Auszahlungsbeleg
          vorfinden.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Warum muss ich meine E-Mail-Adresse verifizieren?">
        <p className="mb-4">
          DropFriends kommuniziert als Online-Plattform weitestgehend per E-Mail mit seinen Nutzern.
        </p>
        <p className="mb-4">
          So erhältst Du z.B. eine E-Mail, sobald Dich ein DropFriend als DropPoint bucht oder wenn
          Du eine Frage an uns gestellt hast.
        </p>
        <p className="mb-4">
          Damit sichergestellt ist, dass DropFriends Dich auch wirklich per E-Mail erreicht, wollen
          wir mit der E-Mail-Verifizierung überprüfen, ob es vielleicht einen Tippfehler in der
          Angabe Deiner E-Mail-Adresse gab oder ob Dein Postfach voll ist.
        </p>
        <p className="mb-4">
          Hast Du Dich also neu registriert oder aber Deine E-Mail-Adresse in der App im Profil
          aktualisiert, dann erhältst Du eine Nachricht in Deine Mailbox. Darin bitten wir Dich
          Deine E-Mail-Adresse per Mausklick bzw. Fingertipp zu bestätigen. Das geht wirklich
          schnell und ist absolut sicher.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Warum ist mein DropFriends-Konto eingeschränkt?">
        <p className="mb-4">
          Einschränkungen von DropFriends-Konten resultieren aus einem nicht erreichbaren
          E-Mail-Postfach oder einem bestehenden Zahlungsrückstand.
        </p>
        <p className="mb-4">
          Auf dies weisen wir mit Hilfe einer gelben Box am Kopf der DropFriends-App hin.
        </p>
        <p className="mb-4">Bitte logge Dich in die App ein und folge den dortigen Anweisungen.</p>
        <p className="mb-4">
          Solltest Du zahlungsrückständig sein, dann haben wir Dich hierüber auch zusätzlich per
          E-Mail in Kenntnis gesetzt. Anweisungen zur Begleichung von offenen Rechnungen finden sich
          im dortigen Schriftverkehr.
        </p>
        <p className="mb-4">
          Beachte: Eingeschränkte DropFriends-Konten können die Übergabe von Sendungen verhindern.
          Sobald Dein DropFriends-Konto wieder freigeschaltet worden ist, kannst Du Deine Sendungen
          natürlich, wie gewohnt, bei Deinem DropPoint entgegennehmen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Warum muss ich ein Profilfoto hinterlegen?">
        <p className="mb-4">
          Der Kern von DropFriends ist das Gemeinschaftsgefühl und der Communitygedanke. Dazu gehört
          auch, dass man seine Online-Bestellungen nicht anonymen Namen überlässt. Ein freundliches
          Lächeln ist schon viel einladender und hat einen weiteren positiven Effekt:
          Identifizierung.
        </p>
        <p className="mb-4">
          Das Profilbild wird grundsätzlich zur Identifizierung von DropFriends-Nutzern verwendet.
          So können DropPoints z.B. sicher sein, dass sie Sendungen an den eigentlichen Eigentümer
          übergeben und die Online-Shopper wissen sofort, ob Pakete auch von keiner anonymen Person
          aufbewahrt worden sind.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie finde ich Buchungen und Sendungen in der DropFriends-App?">
        <p className="mb-4">
          Jede Sendung hat ihren eigenen individuellen Buchungscode. Entsprechend findest Du selbige
          auch stets auf dem Versandetikett der jeweiligen Pakete.
        </p>
        <p className="mb-4">
          Der Code besteht aus einer 6-stelligen Buchstaben-/Zahlenkombination und wird von dem
          Zusatz "FUER-NACHNAME-" begleitet. Beispiel: "FUER-MUSTERMANN-1C23BA".
        </p>
        <p className="mb-4">
          So ist gut für Dich sichtbar gekennzeichnet, dass es sich bei dem Paket um eine
          DropFriends-Buchung handelt. So kommt es nicht zu versehentlichen Verwechslungen mit
          Deinen eigenen persönlichen Sendungen.
        </p>
        <p className="mb-4">
          Ein guter DropFriend stellt sich zudem beispielsweise bei der Abholung seines Eigentums
          mit seinem Namen vor und wird Dir den DropFriends-Buchungscode nennen oder gar auf seinem
          Mobiltelefon zeigen.
        </p>
        <p className="mb-4">
          Solltest Du Buchungen nicht direkt in der App finden, dann gib den 6-stelligen
          Buchungscode in der Suchmaske der DropFriends-App ein.
        </p>
        <p className="mb-4">
          Prüfe auch, ob Deine "Filtereinstellungen" neben dem Suchfeld korrekt eingestellt sind.
          Werden z.B. alle bereits abgeholten Sendungen über die Filter ausgeblendet, dann werden
          Dir keine Suchergebnisse, trotz korrekter Eingabe des 6-stelligen Buchungscodes,
          angezeigt.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wann kommt man die Sendungen abholen?">
        <p className="mb-4">
          Als DropPoint steht es Dir frei selbst zu bestimmen wann Du Sendungen an Deine DropFriends
          übergeben möchtest. So kannst Du Deinen Alltag nach wie vor frei planen und Deine
          Community wird bereits vor der Buchung Deines DropPoints über die möglichen Abholzeiträume
          informiert.
        </p>
        <p className="mb-4">
          So kann jeder DropFriend für sich selbst entscheiden, welche Abholzeiträume für seinen
          Alltag am geeignetsten sind und sich somit den DropPoint buchen, der seinen Anforderungen
          am Besten entspricht.
        </p>
        <p className="mb-4">
          Natürlich kannst Du frei bestimmen, ob Du Übergaben lieber am Morgen, Mittag, Nachmittag,
          Abend oder gar in den Nachtstunden durchführen möchtest. Auch ist es für Dich möglich
          mehrere Abholzeiträume pro Tag zu definieren.
        </p>
        <p className="mb-4">
          Und sind Deine angegebenen Abholzeiten einmal überholt, dann passe sie Deinen täglichen
          Gegebenheiten in Sekundenschnelle an. So bleibst Du auch weiterhin flexibel und kannst
          kommende Sendungen trotzdem erfolgreich übergeben.
        </p>
        <p className="mb-4">
          Beachte aber, dass bereits durchgeführte Buchungen von den Abholzeiten ausgehen, welche Du
          zum Buchungszeitpunkt für Deine Community veröffentlicht hattest.
        </p>
        <p className="mb-4">
          Tipp: Abholzeiten zwischen 19 Uhr und 24 Uhr sind besonders bei der DropFriends-Community
          beliebt. Erweitere Deine Abholzeiten entsprechend, um noch mehr Buchungen zu erhalten und
          somit Deine monatliche Vergütung zu erhöhen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Warum muss ich meinen Personalausweis zur Angabe meines Auszahlungsmittels hinterlegen?">
        <p className="mb-4">
          DropFriends vergütet Dir Deine Annahme von Sendungen für Deine Community. Das bedeutet: Du
          bekommst am Ende des jeweiligen Monats eine echte Geldauszahlung auf Dein Bankkonto.
          Selbiges kannst Du in Deiner DropFriends-App unter dem Menupunkt "Profil" hinterlegen.
          Tippe hierzu auf die Kategorie "Auszahlungsmittel" und ergänze die dortigen Angaben.
        </p>
        <p className="mb-4">
          Deine Vergütungen, Trinkgelder etc. werden in Zusammenarbeit mit unserem
          Zahlungsdienstleister auf ein für Dich seperat angelegtes Verwaltungskonto gesammelt. Dort
          ist es sicher und kann von keinem Dritten veräußert werden.
        </p>
        <p className="mb-4">
          Gesetzlich gibt es im Zahlungsverkehr nämlich strickte und unüberwindbare Regeln. Dazu
          gehört auch, dass unser Zahlungsdienstleister einen Nachweis von Dir verlangen kann, um
          sicherzugehen, dass du auch wirklich du bist.
        </p>
        <p className="mb-4">
          In unserem Fall kann unser Dienstleister Kunden nach einem Reisepass oder einen
          Personalausweis fragen, der dann von ihnen überprüft wird.
        </p>
        <p className="mb-4">
          Sollte also nach einer solchen Identifizierung gebeten werden, dann folge bitte den
          Anweisungen. Ohne diese rechtlich vorgegebene Überprüfung, ist es dann nämlich leider
          nicht möglich eine Auszahlung Deiner bereits gesammelten Vergütungen durchzuführen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Was ist der DropFriends-Buchungscode?">
        <p className="mb-4">
          Jede Sendung hat ihren eigenen individuellen Buchungscode. Entsprechend findest Du
          selbigen auch stets auf dem Versandetikett der jeweiligen Pakete.
        </p>
        <p className="mb-4">
          Der Code besteht aus einer 6-stelligen Buchstaben-/Zahlenkombination und wird von dem
          Zusatz "FUER-NACHNAME-" begleitet. Beispiel: "FUER-MUSTERMANN-1C23BA".
        </p>
        <p className="mb-4">
          So ist gut für Dich sichtbar gekennzeichnet, dass es sich bei dem Paket um eine
          DropFriends-Buchung handelt. So kommt es nicht zu versehentlichen Verwechslungen mit
          Deinen eigenen persönlichen Sendungen.
        </p>
        <p className="mb-4">
          Ein guter DropFriend stellt sich zudem beispielsweise bei der Abholung seines Eigentums
          mit seinem Namen vor und wird Dir den DropFriends-Buchungscode nennen oder gar auf seinem
          Mobiltelefon zeigen. Das dient der Identifizierung von Sendungseigentümern und natürlich
          der korrekten Pakete.
        </p>
        <p className="mb-4">
          Buchungen in der DropFriends-App finden:
          <br />
          Solltest Du Buchungen nicht direkt in der App finden, dann gib den 6-stelligen
          Buchungscode in der Suchmaske der DropFriends-App ein.
        </p>
        <p className="mb-4">
          Prüfe auch, ob Deine "Filtereinstellungen" neben dem Suchfeld korrekt eingestellt sind.
          Werden z.B. alle bereits abgeholten Sendungen über die Filter ausgeblendet, dann werden
          Dir keine Suchergebnisse, trotz korrekter Eingabe des 6-stelligen Buchungscodes,
          angezeigt.
        </p>
      </FAQAccordion>
    </Container>

    <Section>
      <Title>Jetzt die DropFriends-App downloaden.</Title>

      <Text>Erhältlich im App Store und im Google Play Store.</Text>

      <Badges className="mt-4" />
    </Section>
  </PageLayout>
);

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default DropPointFAQ;
